import axios from "axios";
import store from "@/store";

const baseURL = process.env.VUE_APP_BACKEND_URL;

const API = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

API.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let subscribers = [];

function onRefreshed(token) {
  subscribers = subscribers.filter((callback) => callback(token));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // const originalRequest = error.config;
    //
    // if (
    //   (error.response.status === 401 || error.response.status === 403) &&
    //   error.config &&
    //   !originalRequest._isRetry
    // ) {
    //   originalRequest._isRetry = true;
    //
    //   try {
    //     await store.dispatch(
    //       "auth/refreshToken",
    //       localStorage.getItem("refresh")
    //     );
    //     // .then(() => {
    //     //   return API.request(originalRequest);
    //     // })
    //     // .catch(() => {
    //     //   window.location.replace(
    //     //     `${process.env.VUE_APP_PASSPORT_URL}?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`
    //     //   );
    //     // });
    //
    //     return API(originalRequest);
    //   } catch (_error) {
    //     return Promise.reject(_error);
    //   }
    // }

    const originalRequest = error.config;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.promocode) {
      return window.location.replace(
        `${process.env.VUE_APP_PASSPORT_URL}registration?promocode=${params.promocode}&source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`
      );
    }

    if (error?.response?.data?.message === "distributor.banned") {
      location.href = `${process.env.VUE_APP_FRONT_URL}banned`;
    }

    if (error?.response?.data?.message === "no_dd_agreement") {
      location.href = "/agreement";
    }

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      error.config &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;

      // if (
      //   `${window.location.origin}/` !== process.env.VUE_APP_FRONTEND_URL &&
      //   process.env.NODE_ENV === "development"
      // ) {
      //   return window.location.replace(
      //     `https://passport-hub-backend-staging.stage.rccore.net/oauth2/authorize?client_id=38479071-b4da-447d-b142-a17677c25996&client_secret=frontend&one_touch=true&redirect_uri=${window.location.origin}&response_type=token&scope=client%2Cdistributor%2Cpartner`
      //   );
      // }

      if (isRefreshing && error.response.status === 403) {
        return window.location.replace(
          `${process.env.VUE_APP_PASSPORT_URL}?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`
        );
      }

      if (!isRefreshing) {
        isRefreshing = true;

        if (!localStorage.getItem("refresh")) {
          return window.location.replace(
            `${process.env.VUE_APP_PASSPORT_URL}?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`
          );
        } else {
          store
            .dispatch("auth/refreshToken", localStorage.getItem("refresh"))
            .then((response) => {
              isRefreshing = false;

              onRefreshed(response.data.access_token);
            })
            .catch(() => {
              return window.location.replace(
                `${process.env.VUE_APP_PASSPORT_URL}?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`
              );
            });
        }
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      }).catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          return window.location.replace(
            `${process.env.VUE_APP_PASSPORT_URL}?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`
          );
        }
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

export default API;
