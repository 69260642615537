import API from "@/api";
import removeTrailingZeros from "@/package/helpers/format-number.helpers";

const state = {
  analyticsTypes: null,
  statusAnalyticsTypes: "",

  analyticsGraphs: null,
  statusAnalyticsGraphs: "",
};

const getters = {
  getAnalyticsTypeAmountById: (state) => (id) => {
    return (
      removeTrailingZeros(
        state.analyticsTypes[id] > 1000
          ? parseInt(state.analyticsTypes[id])
          : state.analyticsTypes[id],
        2
      ) ?? 0
    );
  },
};

const actions = {
  loadAnalyticsTypes({ commit }, data) {
    commit("SET_STATUS_ANALYTICS_TYPES", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("api/analytics/types", { params: data })
        .then((response) => {
          commit("SET_ANALYTICS_TYPES", response.data[0]);
          commit("SET_STATUS_ANALYTICS_TYPES", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS_ANALYTICS_TYPES", "ERROR");

          reject(error);
        });
    });
  },

  loadAnalyticsGraphs({ commit }, data) {
    commit("SET_STATUS_ANALYTICS_GRAPHS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("api/analytics/graph", { params: data })
        .then((response) => {
          commit("SET_ANALYTICS_GRAPHS", response.data);
          commit("SET_STATUS_ANALYTICS_GRAPHS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS_ANALYTICS_GRAPHS", "ERROR");

          reject(error);
        });
    });
  },
};

const mutations = {
  SET_ANALYTICS_TYPES(state, payload) {
    state.analyticsTypes = payload;
  },

  SET_STATUS_ANALYTICS_TYPES(state, payload) {
    state.statusAnalyticsTypes = payload;
  },

  SET_ANALYTICS_GRAPHS(state, payload) {
    state.analyticsGraphs = payload;
  },

  SET_STATUS_ANALYTICS_GRAPHS(state, payload) {
    state.statusAnalyticsGraphs = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
