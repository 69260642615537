import API from "@/api";

const state = {
  tariffsOfBinaryAccount: null,
  invitedPersonsStat: null,
};

const getters = {};

const actions = {
  changeBinaryAccountMode(_, { binaryAccountId, mode }) {
    return new Promise((resolve, reject) => {
      API.post(`api/binary-accounts/${binaryAccountId}/mode`, {
        mode: mode,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setBirthday(_, birthday) {
    return new Promise((resolve, reject) => {
      API.post("api/auth/birthday", {
        birthday: birthday,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTariffsOfBinaryAccount({ commit }, binaryAccountId) {
    return new Promise((resolve, reject) => {
      API.get(`api/binary-accounts/${binaryAccountId}/tariffs`)
        .then((response) => {
          commit(
            "SET_TARIFFS_OF_BINARY_ACCOUNT",
            response?.data?.tariffs ?? null
          );

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  acceptAgreement(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/agreement/${data.agreementType}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadInvitedPersonsStat({ commit }, binaryAccountId) {
    return new Promise((resolve, reject) => {
      API.get(`api/binary-accounts/${binaryAccountId}/invited-persons-stat`)
        .then((response) => {
          commit("SET_INVITED_PERSONS_STAT", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadInvitedPersonsStat(_, binaryAccountId) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/binary-accounts/${binaryAccountId}/invited-persons-stat/download`,
        {
          responseType: "blob",
        }
      )
        .then((response) => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", "Invited persons statistic");
          const href = URL.createObjectURL(response.data);
          aElement.href = href;
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TARIFFS_OF_BINARY_ACCOUNT(state, payload) {
    state.tariffsOfBinaryAccount = payload;
  },

  SET_INVITED_PERSONS_STAT(state, payload) {
    state.invitedPersonsStat = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
