import API from "@/api";

const state = {
  sberbankRefill: null,
};

const getters = {};

const actions = {
  getInfoWarningSberbankRefill({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/notes/sberbank_refill")
        .then((response) => {
          commit("SET_WARNING_SBERBANK_REFILL", response.data.data);

          resolve(response.data.data);
        })
        .catch((error) => {
          commit("DELETE_WARNING_SBERBANK_REFILL");

          reject(error);
        });
    });
  },
};

const mutations = {
  SET_WARNING_SBERBANK_REFILL(store, payload) {
    store.sberbankRefill = payload;
  },

  DELETE_WARNING_SBERBANK_REFILL(store, _) {
    store.sberbankRefill = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
