import API from "@/api";

const state = {
  binaryList: [],
  binaryListTotal: 0,
  binaryListLevel: 1,
  binaryListPartners: [],

  linearList: [],
  linearListTotal: 0,
  linearListLevel: 1,

  binaryTree: null,

  invitedFree: [],
  invitedFreeCount: null,
};

const getters = {
  getSortedLinearList(state) {
    let sortedLinearList = JSON.parse(JSON.stringify(state.linearList));

    return sortedLinearList.sort((item) => {
      if (item.is_disabled) {
        return 1;
      } else {
        return -1;
      }
    });
  },
};

const actions = {
  loadBinaryList({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/structure/binary/list", {
        params: {
          bin_acc_id: data.binaryAccountId,
          level: data.level,
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_BINARY_LIST_TOTAL", response.data.total);
          commit("SET_BINARY_LIST_LEVEL", response.data.level);

          if (data.page === 1) {
            commit("SET_BINARY_LIST", response.data.data);
          } else {
            commit("ADD_BINARY_LIST", response.data.data);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadBinaryListPartners({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/structure/binary/search`, {
        params: {
          search: payload.search,
        },
      })
        .then((response) => {
          commit("SET_BINARY_LIST_PARTNERS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadLinearList({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/structure/linear/list", {
        params: {
          bin_acc_id: data.binaryAccountId,
          level: data.level,
          page: data.page,
        },
      })
        .then((response) => {
          commit("SET_LINEAR_LIST_TOTAL", response.data.total);
          commit("SET_LINEAR_LIST_LEVEL", response.data.level);

          if (data.page === 1) {
            commit("SET_LINEAR_LIST", response.data.data);
          } else {
            commit("ADD_LINEAR_LIST", response.data.data);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadBinaryTree({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/structure/binary/tree", {
        params: {
          bin_acc_id: data.binaryAccountId,
          limit: data.limit,
        },
      })
        .then((response) => {
          let array = response.data;

          function buildTree(array) {
            const map = new Map(array.map((item) => [item.tree_id, item]));

            for (let item of map.values()) {
              if (item.parent_position === "") {
                continue;
              }

              const parent = map.get(item.parent_tree_id);

              if (!parent?.children) {
                parent.children = {
                  left: null,
                  right: null,
                };
              }
              parent.children[item.position] = item;
            }

            return [...map.values()].find(
              (item) => item.parent_position === ""
            );
          }

          const tree = buildTree(array);

          commit("SET_BINARY_TREE", tree);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadInvitedFreeCount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/binary-accounts/${payload.binaryAccount}/invited-free-count`)
        .then((response) => {
          commit("SET_INVITED_FREE_COUNT", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadInvitedFree({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/binary-accounts/${payload.binaryAccount}/free-linear`, {
        params: {
          page: payload.page,
          type: payload.type,
        },
      })
        .then((response) => {
          if (payload.page === 1) {
            commit("SET_INVITED_FREE", response.data.data.free);
          } else {
            commit("ADD_INVITED_FREE", response.data.data.free);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_BINARY_LIST(state, payload) {
    state.binaryList = payload;
  },

  SET_BINARY_LIST_PARTNERS(state, payload) {
    state.binaryListPartners = payload;
  },

  ADD_BINARY_LIST(state, payload) {
    state.binaryList = [...state.binaryList, ...payload];
  },

  SET_BINARY_LIST_TOTAL(state, payload) {
    state.binaryListTotal = payload;
  },

  SET_BINARY_LIST_LEVEL(state, payload) {
    state.binaryListLevel = payload;
  },

  SET_LINEAR_LIST(state, payload) {
    state.linearList = payload;
  },

  ADD_LINEAR_LIST(state, payload) {
    state.linearList = [...state.linearList, ...payload];
  },

  SET_LINEAR_LIST_LEVEL(state, payload) {
    state.linearListLevel = payload;
  },

  SET_LINEAR_LIST_TOTAL(state, payload) {
    state.linearListTotal = payload;
  },

  SET_BINARY_TREE(state, payload) {
    state.binaryTree = payload;
  },

  SET_INVITED_FREE(state, payload) {
    state.invitedFree = payload;
  },

  ADD_INVITED_FREE(state, payload) {
    state.invitedFree = [...state.invitedFree, ...payload];
  },

  SET_INVITED_FREE_COUNT(state, payload) {
    state.invitedFreeCount = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
