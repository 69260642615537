import API from "@/api";
import store from "@/store/index";

const state = {
  marketSpent: 0,
  marketTarget: 3000,
  subscriptions: [],
  marketConditionFulfilled: false,
  tariffConditionFulfilled: false,
  isShowAnimation: false,
};

const getters = {};

const actions = {
  loadPersonalMarketStat({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/mini-market")
        .then((response) => {
          commit("SET_MARKET_SPENT", response.data.data.market_spent);
          commit("SET_SUBSCRIPTION", response.data.data.subscriptions);
          store.dispatch("market/changeLocalStorageData");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkSubscription({ commit, state }) {
    commit(
      "SET_TARIFF",
      !!state.subscriptions.find(
        (account) =>
          account.bin_acc_id === store.state.auth.selectedBinaryAccount.id
      ).is_active
    );
  },

  changeLocalStorageData({ commit, state }) {
    if (
      !localStorage.hasOwnProperty("marketCondition") &&
      state.marketConditionFulfilled
    ) {
      commit("SET_ANIMATION", true);
    }

    state.subscriptions.forEach((item) => {
      if (
        store.state.auth.selectedBinaryAccount.id === item.bin_acc_id &&
        !localStorage.hasOwnProperty(`${item.bin_acc_id}`) &&
        item.is_active
      ) {
        commit("SET_ANIMATION", true);
      }
    });
  },
};

const mutations = {
  SET_MARKET_SPENT(state, market_spent) {
    state.marketSpent = market_spent;
    state.marketConditionFulfilled = market_spent >= state.marketTarget;
  },

  SET_SUBSCRIPTION(state, subscriptions) {
    state.subscriptions = subscriptions;
    store.dispatch("market/checkSubscription");
  },

  SET_ANIMATION(state, value) {
    state.isShowAnimation = value;
  },

  SET_TARIFF(state, value) {
    state.tariffConditionFulfilled = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
