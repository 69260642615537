import API from "@/api";
import { DateTime } from "luxon";
import GroupDateArray from "@/package/helpers/group-date-array.helpers";

const state = {
  currentBalance: null,
  incomeBalance: null,

  // !Не использовать
  cardInfo: null,

  limits: null,
  gifteryCycles: null,

  // * New state

  combinations: [],
  stats: null,
  monthOperations: [],
  dayOperations: [],

  statusMonthOperations: "",

  withdrawals: [],
  withdrawalsMeta: null,
  withdrawalsAmount: 0,
  isLoadingWithdrawals: true,
};

const getters = {
  isLastPageWithdrawals(state) {
    if (!state.withdrawalsMeta) return false;

    return state.withdrawalsMeta.page >= state.withdrawalsMeta.last_page;
  },
};

const actions = {
  loadCurrentBalance({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/balance/current")
        .then((response) => {
          commit("SET_CURRENT_BALANCE", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadIncomeBalance({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/balance/income")
        .then((response) => {
          commit("SET_INCOME_BALANCE", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadIncomeBalance() {
    return new Promise((resolve, reject) => {
      API.get("api/balance/income/download", {
        responseType: "blob",
      })
        .then((response) => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", "Statistic");
          const href = URL.createObjectURL(response.data);
          aElement.href = href;
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCardNumber(_, payload) {
    return new Promise((resolve, reject) => {
      API.put("api/card", {
        card: payload.cardNumber,
        first_name: payload.firstName,
        last_name: payload.lastName,
        middle_name: payload.middleName,
        birthday: payload.birthday,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // !Вынужденный метод для получения данных пользователя на странице вывода средств
  loadCardInfo({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/card")
        .then((response) => {
          commit("SET_CARD_INFO", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  refillBalance(_, { amount, currency, payment_method }) {
    return new Promise((resolve, reject) => {
      API.post("api/refill", {
        amount: amount,
        currency: currency,
        payment_method: payment_method,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkLimitsByCurrency({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/refill/check", {
        params: {
          currency: data.currency,
        },
      })
        .then((response) => {
          commit("SET_LIMITS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadGifteryCycles({ commit }, { binaryAccount }) {
    return new Promise((resolve, reject) => {
      API.get(`api/binary-accounts/${binaryAccount}/giftery-cycles`)
        .then((response) => {
          commit("SET_GIFTERY_CYCLES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // * New methods

  loadCombinations({ commit }, categories) {
    return new Promise((resolve, reject) => {
      API.get(`api/balance/combinations`, {
        params: {
          categories: categories,
        },
      })
        .then((response) => {
          commit("SET_COMBINATIONS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadStats({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/balance/${data.binaryAccount}/stats`, {
        combinations: data.combinations,
        from_date: data.dateFrom,
        to_date: data.dateTo,
      })
        .then((response) => {
          commit("SET_STATS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadMonthOperations({ commit }, data) {
    commit("SET_STATUS_MONTH_OPERATIONS", "LOADING");

    return new Promise((resolve, reject) => {
      API.post(`api/balance/${data.binaryAccount}/month`, {
        combinations: data.combinations,
        from_date: data.dateFrom,
        to_date: data.dateTo,
      })
        .then((response) => {
          commit("SET_MONTH_OPERATIONS", response.data.data.days);
          commit("SET_STATUS_MONTH_OPERATIONS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS_MONTH_OPERATIONS", "ERROR");

          reject(error);
        });
    });
  },

  loadDayOperations({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/balance/${data.binaryAccount}/day`, {
        combinations: data.combinations,
        day: data.day,
        page: data.page,
        per_page: 15,
      })
        .then((response) => {
          commit("SET_DAY_OPERATIONS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Получение информации о статусах вывовода средств
  getHistoryWithdrawals({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/withdrawals`, { params: payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getForMountWithdrawals({ commit, dispatch }, payload) {
    try {
      commit("TOGGLE_LOADING_WITHDRAWALS", true);

      const response = await dispatch("getHistoryWithdrawals", payload);

      let withdrawals = [];

      if (response.data.data.operations) {
        withdrawals = GroupDateArray(response.data.data.operations);
      }

      commit("SET_HISTORY_WITHDRAWALS", {
        withdrawals,
        withdrawalsMeta: response.data.meta,
        withdrawalsAmount: response.data.data.total_amount,
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("TOGGLE_LOADING_WITHDRAWALS", false);
    }
  },

  async getNextWithdrawals({ commit, state, dispatch, getters }) {
    if (getters.isLastPageWithdrawals) return;

    commit("TOGGLE_LOADING_WITHDRAWALS", true);

    try {
      const response = await dispatch("getHistoryWithdrawals", {
        ...state.withdrawalsMeta,
        page: state.withdrawalsMeta.page + 1,
      });

      const withdrawals = GroupDateArray(
        response.data.data.operations,
        state.withdrawals
      );

      commit("UPDATE_HISTORY_WITHDRAWALS", {
        withdrawals: withdrawals,
        withdrawalsMeta: response.data.meta,
      });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("TOGGLE_LOADING_WITHDRAWALS", false);
    }
  },
};

const mutations = {
  SET_CURRENT_BALANCE(state, payload) {
    state.currentBalance = payload;
  },

  SET_INCOME_BALANCE(state, payload) {
    state.incomeBalance = payload;
  },

  // !Не использовать
  SET_CARD_INFO(state, payload) {
    state.cardInfo = payload;
  },

  SET_LIMITS(state, payload) {
    state.limits = payload;
  },

  SET_GIFTERY_CYCLES(state, payload) {
    state.gifteryCycles = payload;
  },

  // * New mutations
  SET_COMBINATIONS(state, payload) {
    state.combinations = payload;
  },

  SET_STATS(state, payload) {
    state.stats = payload;
  },

  SET_MONTH_OPERATIONS(state, payload) {
    state.monthOperations = payload;
  },

  SET_DAY_OPERATIONS(state, payload) {
    state.dayOperations = payload;
  },

  SET_STATUS_MONTH_OPERATIONS(state, payload) {
    state.statusMonthOperations = payload;
  },

  SET_HISTORY_WITHDRAWALS(state, payload) {
    state.withdrawals = payload.withdrawals || [];
    state.withdrawalsMeta = payload.withdrawalsMeta;
    state.withdrawalsAmount = payload.withdrawalsAmount;
  },

  UPDATE_HISTORY_WITHDRAWALS(state, payload) {
    state.withdrawals = payload.withdrawals;
    state.withdrawalsMeta = payload.withdrawalsMeta;
  },

  TOGGLE_LOADING_WITHDRAWALS(state, payload) {
    state.isLoadingWithdrawals = payload ?? !state.isLoadingWithdrawals;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
