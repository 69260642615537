import API from "@/api";

const state = {
  code: "",
  user: null,

  selectedBinaryAccount: null,

  selectedLocale: {
    id: "RUB",
    name: "RU",
  },

  notifications: [],
};

const getters = {
  getLocaleAccounts: (state) => (locale) => {
    return state.user.accounts.filter(
      (account) =>
        account.currency === locale && account.account_type !== "gifter"
    );
  },

  getUserDistributorAccount: (state) => {
    return state.user.accounts.find(
      (account) =>
        account.user_type === "distributor" &&
        account.account_type === "rc" &&
        account.currency === state.selectedLocale.id
    );
  },

  getUserAccounts: (state) => {
    return state.user.accounts.filter(
      (account) => account.account_type !== "gifter"
    );
  },

  getUserGifteryAccount: (state) => {
    return state.user.accounts.find(
      (account) => account.account_type === "gifter"
    );
  },

  getUserAccountById: (state) => (id) => {
    return state.user.accounts.find((accounts) => accounts.id === id);
  },

  getUserBinaryAccounts: (state) => {
    return state.user.binaryAccounts;
  },

  getUserBinaryAccountById: (state) => (id) => {
    return state.user.binaryAccounts.find(
      (binaryAccount) => binaryAccount.id === id
    );
  },
};

const actions = {
  refreshToken(_, refreshToken) {
    return new Promise((resolve, reject) => {
      API.post(`${process.env.VUE_APP_PASSPORT_BACKEND_URL}oauth2/token`, {
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        client_id: "a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c",
      })
        .then((response) => {
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh", response.data.refresh_token);

          resolve(response);
        })
        .catch((error) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");

          reject(error);
        });
    });
  },

  checkUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/auth/check")
        .then((response) => {
          commit("SET_USER", response?.data?.data);

          if (!state.selectedBinaryAccount) {
            const masterBinaryAccount =
              response?.data?.data?.binaryAccounts.find((binaryAccount) => {
                return binaryAccount.position === "master";
              }) ?? null;

            if (response?.data?.data?.email === "egorpatov@gmail.com") {
              commit(
                "SET_BINARY_ACCOUNT",
                response.data.data.binaryAccounts.find((binaryAccount) => {
                  return (
                    binaryAccount.id === "257983fb-b79a-4031-8ae0-e023072b3da3"
                  );
                })
              );
            } else if (masterBinaryAccount) {
              commit("SET_BINARY_ACCOUNT", masterBinaryAccount);
            } else {
              commit(
                "SET_BINARY_ACCOUNT",
                response?.data?.data?.binaryAccounts?.[0] ?? null
              );
            }
          } else {
            commit(
              "SET_BINARY_ACCOUNT",
              response.data.data.binaryAccounts.find(
                (account) => account.id === state.selectedBinaryAccount.id
              )
            );
          }

          resolve(response);
        })
        .catch((error) => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());

          if (error?.response?.data?.message === "distributor.banned") {
            location.href = `${process.env.VUE_APP_FRONT_URL}/banned`;
            return;
          }

          if (params?.promocode) {
            location.href = `${process.env.VUE_APP_PASSPORT_URL}registration?promocode=${params.promocode}&source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`;
          } else if (
            error.response?.status !== 401 &&
            error.response?.status !== 403
          ) {
            // if (
            //   `${window.location.origin}/` !==
            //     process.env.VUE_APP_FRONTEND_URL &&
            //   process.env.NODE_ENV === "development"
            // ) {
            //   return window.location.replace(
            //     `https://passport-hub-backend-staging.stage.rccore.net/oauth2/authorize?client_id=38479071-b4da-447d-b142-a17677c25996&client_secret=frontend&one_touch=true&redirect_uri=${window.location.origin}&response_type=token&scope=client%2Cdistributor%2Cpartner`
            //   );
            // } else {
            location.href = `${process.env.VUE_APP_PASSPORT_URL}?source=a196bda7-cbc7-4b35-9aaa-f73c62fb3c3c`;
            // }
          }

          reject(error);
        });
    });
  },

  validateEmail(_, email) {
    return new Promise((resolve, reject) => {
      API.post("api/auth/email/set", {
        email: email,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setPromoСode(_, promocode) {
    return new Promise((resolve, reject) => {
      API.post("api/auth/promocode", {
        promocode: promocode,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateSelfEmployed(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("api/auth/self-employed", {
        is_self_employed: payload.isSelfEmployed,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      API.get("api/auth/logout")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setINN(_, inn) {
    return new Promise((resolve, reject) => {
      API.post("api/auth/inn", {
        inn: inn,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/auth/notifications/latest")
        .then((response) => {
          commit("SET_NOTIFICATIONS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  viewNotification(_, id) {
    return new Promise((resolve, reject) => {
      API.put(`api/auth/notifications/view/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_BINARY_ACCOUNT(state, payload) {
    state.selectedBinaryAccount = payload;
  },

  SET_LOCALE(state, payload) {
    state.selectedLocale = payload;
  },

  SET_SELF_EMPLOYED(state, payload) {
    state.user.is_self_employed = payload;
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
};

export default {
  namespaced: true,
  name: "auth",
  state,
  getters,
  actions,
  mutations,
};
