import API from "@/api";

const state = {
  subscriptions: [],
  subscriptionPaymentMode: null,

  subscriptionHistory: [],
  subscriptionHistoryLastPage: 1,
};

const getters = {};

const actions = {
  loadSubscriptions({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/subscription-fee")
        .then((response) => {
          commit("SET_SUBSCRIPTIONS", response.data.list);
          commit("SET_SUBSCRIPTIONS_PAYMENT_MODE", response.data.frequency);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  paySubscription(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(`api/subscription-fee/${payload.binaryAccountId}/pay`, {
        mode: payload.mode,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeSubscriptionPaymentMode(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("api/subscription-fee/mode", {
        mode: payload.mode,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  switchSubscriptionActivation(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(`api/subscription-fee/${payload.binaryAccountId}/switch`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadSubscriptionHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get("api/subscription-fee/history", {
        params: {
          page: payload.page,
        },
      })
        .then((response) => {
          if (payload.page === 1) {
            commit("SET_SUBSCRIPTION_HISTORY", response.data.data);
          } else {
            commit("ADD_SUBSCRIPTION_HISTORY", response.data.data);
          }
          commit(
            "SET_SUBSCRIPTION_HISTORY_LAST_PAGE",
            response.data.meta.last_page
          );

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_SUBSCRIPTIONS(state, payload) {
    state.subscriptions = payload;
  },

  SET_SUBSCRIPTIONS_PAYMENT_MODE(state, payload) {
    state.subscriptionPaymentMode = payload;
  },

  SET_SUBSCRIPTION_HISTORY(state, payload) {
    state.subscriptionHistory = payload;
  },

  ADD_SUBSCRIPTION_HISTORY(state, payload) {
    state.subscriptionHistory = state.subscriptionHistory.concat(payload);
  },

  SET_SUBSCRIPTION_HISTORY_LAST_PAGE(state, payload) {
    state.subscriptionHistoryLastPage = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
