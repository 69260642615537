<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { MENU_ITEMS } from "@/package/const/menu-items";
import AsideProfile from "./AsideProfile.vue";

export default {
  components: {
    AsideProfile,
  },

  data() {
    return {
      windowWidth: window.innerWidth,

      menuItems: MENU_ITEMS,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showMenu: (state) => state.showMenu,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    showCloseButton() {
      return this.windowWidth <= 1023;
    },
  },

  methods: {
    ...mapMutations({
      setShowMenu: "SET_SHOW_MENU",
    }),

    ...mapActions({
      logout: "auth/logout",
    }),

    logoutRequest() {
      this.logout().then(() => {
        location.href = process.env.VUE_APP_FRONT_URL;
      });
    },

    checkAllowedTariffCodes(item) {
      return item?.allowedTariffCodes
        ? item.allowedTariffCodes.includes(this.user?.tariff?.code)
        : true;
    },

    checkAllowedUsers(item) {
      return item?.allowedUsers
        ? item.allowedUsers.includes(this.user?.phone)
        : true;
    },

    showRouterLink(menuItem) {
      return this.checkAllowedTariffCodes(menuItem);
    },

    showMenuItem(menuItem) {
      return menuItem?.nestedItems && this.checkAllowedTariffCodes(menuItem);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    hideMenu() {
      if (this.windowWidth <= 1023) {
        this.setShowMenu(false);
      }
    },

    sortedNestedItems(nestedItems) {
      return nestedItems.filter((item) => {
        return (
          this.checkAllowedTariffCodes(item) && this.checkAllowedUsers(item)
        );
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    if (!this.selectedBinaryAccount) {
      const bonusesItemIndex = this.menuItems.findIndex((item) => {
        return item.title === "Бонусы";
      });

      this.menuItems.splice(bonusesItemIndex, 1);
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<template>
  <div class="aside" :class="{ aside_opened: showMenu }">
    <div class="aside__logo">
      <svg
        width="114"
        height="36"
        viewBox="0 0 114 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3326 30.0359H24.0141C24.0447 30.0359 24.074 30.0481 24.0956 30.0699C24.1173 30.0917 24.1294 30.1214 24.1294 30.1522C24.1294 31.4174 23.6313 32.6307 22.7446 33.5254C21.8579 34.42 20.6553 34.9226 19.4013 34.9226H6.45789C4.74515 34.9226 3.10254 34.2361 1.89145 33.0142C0.680364 31.7923 0 30.135 0 28.407V15.7992C0 15.6689 0 15.5432 0 15.4129C0.198349 12.9696 3.86089 13.2023 3.86089 15.6503V23.5202C3.86089 24.3771 4.02835 25.2255 4.35376 26.0169C4.67916 26.8083 5.15611 27.5272 5.75724 28.1324C6.35838 28.7376 7.07193 29.2173 7.85704 29.5439C8.64214 29.8705 9.48341 30.0377 10.3326 30.0359Z"
          fill="url(#paint0_linear_11847_1177)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.0685 1.125H22.6813C23.1706 1.125 23.6399 1.32113 23.9859 1.67025C24.332 2.01937 24.5264 2.49288 24.5264 2.98661V5.31361H16.2234V2.98661C16.2234 2.49288 16.4178 2.01937 16.7638 1.67025C17.1098 1.32113 17.5791 1.125 18.0685 1.125ZM7.58339 7.1752H33.152C33.6414 7.1752 34.1107 7.37133 34.4567 7.72045C34.8027 8.06957 34.9972 8.54308 34.9972 9.0368V13.4162C34.9972 14.6505 34.5112 15.8343 33.6461 16.7071C32.781 17.5799 31.6078 18.0702 30.3844 18.0702H23.0042C23.0377 17.9099 23.0887 17.7539 23.1564 17.6049L24.4434 14.7288L21.588 16.0226C21.2089 16.1951 20.7977 16.2844 20.3818 16.2844C19.9659 16.2844 19.5547 16.1951 19.1756 16.0226L16.3203 14.7288L17.6072 17.6049C17.6727 17.7547 17.7237 17.9105 17.7594 18.0702H10.3511C10.35 18.0702 10.3489 18.0702 10.3478 18.0702C9.13572 18.062 7.97502 17.5726 7.1173 16.7072C6.25224 15.8344 5.76624 14.6506 5.76624 13.4163V13.928C5.7477 13.7588 5.73828 13.588 5.73828 13.4162V9.0368C5.73828 8.54308 5.9327 8.06957 6.27873 7.72045C6.62475 7.37133 7.09404 7.1752 7.58339 7.1752ZM10.3467 19.9319C9.4882 19.9318 8.63839 19.7582 7.84742 19.4215C7.05511 19.0841 6.33792 18.5898 5.73828 17.9679V23.5527C5.73828 24.787 6.22428 25.9708 7.08934 26.8436C7.95441 27.7164 9.12767 28.2067 10.3511 28.2067H30.3844C31.6078 28.2067 32.781 27.7164 33.6461 26.8436C34.5112 25.9708 34.9972 24.787 34.9972 23.5527V17.9679C34.985 17.9805 34.9727 17.9931 34.9605 18.0056V18.0285C34.3636 18.6319 33.6547 19.1106 32.8743 19.4372C32.0939 19.7638 31.2572 19.9319 30.4123 19.9319H23.1195C23.1248 19.9481 23.1336 19.9642 23.1423 19.9803C23.1563 20.0061 23.1702 20.0318 23.1702 20.0576L24.4572 22.9385L21.6019 21.6446C21.2227 21.4721 20.8116 21.3829 20.3956 21.3829C19.9797 21.3829 19.5686 21.4721 19.1894 21.6446L16.3341 22.9385L17.6211 20.0576C17.6404 20.0173 17.6559 19.9752 17.6672 19.9319H10.379C10.3682 19.932 10.3575 19.9319 10.3467 19.9319Z"
          fill="url(#paint1_linear_11847_1177)"
        />
        <path
          d="M30.385 18.0703H23.0045C23.038 17.9099 23.089 17.7538 23.1567 17.6049L24.4437 14.7287L21.5884 16.0225C21.2093 16.195 20.7981 16.2843 20.3822 16.2843C19.9662 16.2843 19.5551 16.195 19.1759 16.0225L16.3206 14.7287L17.6076 17.6049C17.6731 17.7547 17.7241 17.9105 17.7598 18.0703H10.3794C9.15599 18.0703 7.98273 17.5799 7.11766 16.7071C6.2526 15.8343 5.7666 14.6506 5.7666 13.4163V17.9679C6.36624 18.5898 7.08343 19.0841 7.87574 19.4215C8.66806 19.7588 9.5194 19.9324 10.3794 19.9319H17.6675C17.6562 19.9752 17.6408 20.0173 17.6214 20.0575L16.3345 22.9384L19.1898 21.6446C19.5689 21.472 19.9801 21.3828 20.396 21.3828C20.8119 21.3828 21.2231 21.472 21.6022 21.6446L24.4576 22.9384L23.1706 20.0575C23.1706 20.0157 23.1337 19.9738 23.1199 19.9319H30.4126C31.2576 19.9319 32.0942 19.7638 32.8747 19.4371C33.6551 19.1105 34.364 18.6318 34.9608 18.0284V14.1702C34.783 15.2634 34.2245 16.2567 33.3857 16.9716C32.547 17.6865 31.483 18.076 30.385 18.0703Z"
          fill="url(#paint2_radial_11847_1177)"
        />
        <path
          d="M5.73828 23.5527V24.2974H5.80287C5.7607 24.0514 5.73909 23.8023 5.73828 23.5527Z"
          fill="white"
        />
        <path
          d="M39.4668 22.4629V12.3748H43.5796C44.3916 12.3616 45.1975 12.5116 45.9449 12.8153C46.5617 13.0657 47.0866 13.4859 47.4526 14.0224C47.8121 14.587 47.9945 15.2383 47.9782 15.899C47.9975 16.5615 47.8149 17.215 47.4526 17.7801C47.0855 18.3146 46.5608 18.7331 45.9449 18.9827C45.1982 19.2885 44.3918 19.4386 43.5796 19.4233H40.2967L40.9745 18.7625V22.4629H39.4668ZM40.9745 18.8814L40.2967 18.1722H43.5242C44.3061 18.2248 45.0825 18.0144 45.719 17.5774C45.9538 17.3602 46.1405 17.1 46.268 16.8124C46.3955 16.5249 46.4612 16.2158 46.4612 15.9034C46.4612 15.5911 46.3955 15.282 46.268 14.9944C46.1405 14.7069 45.9538 14.4467 45.719 14.2294C45.0809 13.796 44.3053 13.5874 43.5242 13.6391H40.2967L40.9745 12.9211V18.8814ZM46.5074 22.4629L43.8286 18.8065H45.4377L48.1488 22.4629H46.5074Z"
          fill="#212121"
        />
        <path
          d="M54.5163 22.5774C53.7568 22.5853 53.0026 22.4553 52.2939 22.1941C51.6355 21.9538 51.0359 21.5864 50.5326 21.1149C50.0351 20.6503 49.6387 20.0964 49.3661 19.4849C48.8006 18.1651 48.8006 16.6858 49.3661 15.366C49.6403 14.7531 50.0401 14.1989 50.5419 13.736C51.0469 13.2645 51.648 12.8971 52.3078 12.6567C53.7505 12.1369 55.342 12.1369 56.7848 12.6567C57.4569 12.9107 58.057 13.3121 58.5369 13.8285L57.5594 14.736C57.177 14.355 56.7152 14.0548 56.2038 13.8549C55.6916 13.6558 55.1431 13.5555 54.5901 13.5598C54.0274 13.5552 53.4689 13.6524 52.9441 13.8461C52.4531 14.0291 52.005 14.3031 51.6254 14.6523C51.2543 15.0074 50.964 15.4318 50.7724 15.899C50.3644 16.8899 50.3644 17.9918 50.7724 18.9827C50.9691 19.4453 51.2643 19.8639 51.6392 20.2118C52.0188 20.561 52.467 20.835 52.9579 21.0179C53.4821 21.2138 54.0411 21.311 54.6039 21.3043C55.1569 21.3086 55.7054 21.2083 56.2177 21.0091C56.735 20.8055 57.1982 20.4939 57.5732 20.0972L58.5507 21.0047C58.0697 21.522 57.4701 21.9261 56.7986 22.1853C56.072 22.4574 55.2966 22.5906 54.5163 22.5774Z"
          fill="#212121"
        />
        <path
          d="M66.788 22.5873C66.0221 22.5947 65.2617 22.4632 64.5472 22.1996C63.8836 21.9539 63.2773 21.5857 62.7628 21.1159C62.2517 20.6358 61.8458 20.0633 61.5688 19.4317C61.2917 18.8002 61.149 18.1223 61.149 17.4375C61.149 16.7527 61.2917 16.0748 61.5688 15.4432C61.8458 14.8117 62.2517 14.2392 62.7628 13.7591C63.2763 13.2903 63.881 12.9221 64.5426 12.6754C65.9822 12.1644 67.5661 12.1644 69.0057 12.6754C69.6669 12.921 70.2714 13.2876 70.7855 13.7546C71.2903 14.2168 71.6905 14.7731 71.9612 15.389C72.5082 16.7107 72.5082 18.1818 71.9612 19.5036C71.6905 20.1195 71.2903 20.6757 70.7855 21.1379C70.2726 21.6064 69.6677 21.9732 69.0057 22.2172C68.2976 22.4733 67.5451 22.5988 66.788 22.5873ZM66.788 21.3097C67.3421 21.3146 67.8919 21.2158 68.4064 21.019C68.8895 20.8348 69.3287 20.5591 69.6974 20.2084C70.0626 19.8547 70.3537 19.4375 70.5549 18.9793C70.9667 17.9891 70.9667 16.8859 70.5549 15.8956C70.3537 15.4375 70.0626 15.0203 69.6974 14.6665C69.3273 14.3154 68.8866 14.0396 68.4017 13.856C67.8873 13.6591 67.3375 13.5603 66.7834 13.5652C66.2231 13.5591 65.6671 13.6579 65.1466 13.856C64.6611 14.0404 64.2191 14.3159 63.8469 14.6662C63.4747 15.0165 63.1798 15.4346 62.9795 15.8956C62.5678 16.8859 62.5678 17.9891 62.9795 18.9793C63.1798 19.4404 63.4747 19.8584 63.8469 20.2088C64.2191 20.5591 64.6611 20.8346 65.1466 21.019C65.6685 21.2176 66.2262 21.3164 66.788 21.3097Z"
          fill="#212121"
        />
        <path
          d="M75.5806 22.4727H74.0775V12.4022H81.5146V13.6533H75.5806V22.4727ZM75.4331 17.1864H80.8691V18.4243H75.4331V17.1864Z"
          fill="#212121"
        />
        <path
          d="M84.5439 22.4727H83.0362V12.4022H90.4779V13.6533H84.5439V22.4727ZM84.3918 17.1864H89.8278V18.4243H84.3918V17.1864Z"
          fill="#212121"
        />
        <path
          d="M91.9994 22.4727V12.4022H93.5025V22.4727H91.9994Z"
          fill="#212121"
        />
        <path
          d="M100.778 22.5872C100.019 22.5966 99.2649 22.468 98.5559 22.2084C97.8983 21.9663 97.2991 21.5992 96.7946 21.1291C96.2969 20.663 95.9005 20.1076 95.6281 19.4947C95.0687 18.1753 95.0687 16.6997 95.6281 15.3802C95.9022 14.766 96.3019 14.2104 96.8038 13.7458C97.3137 13.2781 97.9155 12.9113 98.5743 12.6665C99.2833 12.4069 100.037 12.2783 100.797 12.2877C101.566 12.2808 102.33 12.4139 103.047 12.6798C103.72 12.9341 104.32 13.3372 104.799 13.856L103.821 14.737C103.439 14.356 102.977 14.0559 102.466 13.856C101.954 13.6589 101.408 13.5587 100.857 13.5608C100.292 13.5556 99.7316 13.6543 99.206 13.8516C98.7139 14.0323 98.2653 14.3065 97.8873 14.6577C97.5124 15.0056 97.2172 15.4242 97.0205 15.8868C96.6111 16.8775 96.6111 17.9798 97.0205 18.9705C97.2172 19.4332 97.5124 19.8517 97.8873 20.1996C98.2653 20.5508 98.7139 20.825 99.206 21.0057C99.7316 21.203 100.292 21.3017 100.857 21.2965C101.408 21.2986 101.954 21.1984 102.466 21.0013C102.982 20.7944 103.445 20.4816 103.821 20.085L104.799 20.9925C104.319 21.5113 103.719 21.9156 103.047 22.1732C102.326 22.4518 101.555 22.5925 100.778 22.5872Z"
          fill="#212121"
        />
        <path
          d="M107.856 21.2216H114.002V22.4727H106.353V12.4022H113.794V13.6533H107.856V21.2216ZM107.722 16.7459H113.144V17.9705H107.722V16.7459Z"
          fill="#212121"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11847_1177"
            x1="-48.6877"
            y1="2.68245"
            x2="55.6317"
            y2="91.5181"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.03" stop-color="#FFDA7A" />
            <stop offset="0.62" stop-color="#FFCC49" />
            <stop offset="1" stop-color="#FFCC49" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_11847_1177"
            x1="9.21752"
            y1="4.82617"
            x2="28.2762"
            y2="33.7632"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#212121" />
            <stop offset="0.96" stop-color="#434343" />
            <stop offset="1" stop-color="#444444" />
          </linearGradient>
          <radialGradient
            id="paint2_radial_11847_1177"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(20.3637 18.1773) scale(14.5971 9.87103)"
          >
            <stop offset="0.35" stop-color="#FFDA7A" />
            <stop offset="0.68" stop-color="#FFF3D3" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>

      <button v-if="showCloseButton" @click="setShowMenu(false)">
        <img src="../../assets/icons/close.svg" alt="" />
      </button>
    </div>

    <AsideProfile />

    <nav class="aside__menu menu">
      <div v-for="menuItem in menuItems" :key="menuItem.title">
        <template v-if="showMenuItem(menuItem) && menuItem.title !== 'Помощь'">
          <div
            class="menu__item"
            :class="menuItem.showNestedItems ? 'menu__item_opened' : ''"
            @click="menuItem.showNestedItems = !menuItem.showNestedItems"
          >
            <img :src="menuItem.icon" :alt="menuItem.title" />
            {{ menuItem.title }}
            <svg
              class="arrow"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.49997 10.2803C7.35353 10.1339 7.35353 9.89642 7.49997 9.74997C7.64642 9.60352 7.88386 9.60353 8.0303 9.74997L11.8674 13.5871C11.9406 13.6603 12.0593 13.6603 12.1326 13.5871L15.9696 9.74997C16.1161 9.60353 16.3535 9.60353 16.5 9.74997C16.6464 9.89642 16.6464 10.1339 16.5 10.2803L12.2651 14.5151C12.1187 14.6616 11.8813 14.6616 11.7348 14.5151L7.49997 10.2803Z"
                fill="#212121"
              />
            </svg>
          </div>

          <div
            class="menu submenu"
            :class="menuItem.showNestedItems ? 'submenu_opened' : ''"
          >
            <router-link
              v-for="nestedItem in sortedNestedItems(menuItem.nestedItems)"
              :key="nestedItem.href"
              class="menu__item"
              exact-active-class="menu__item_active"
              :to="{ path: nestedItem.href }"
              @click.native="hideMenu"
            >
              {{ nestedItem.title }}
            </router-link>
          </div>
        </template>

        <template v-else-if="menuItem.title === 'Помощь'">
          <a class="menu__item" :href="menuItem.href" target="_blank">
            <img :src="menuItem.icon" :alt="menuItem.title" />
            {{ menuItem.title }}
          </a>
        </template>

        <template v-else-if="showRouterLink(menuItem)">
          <router-link
            class="menu__item"
            exact-active-class="menu__item_active"
            :to="{ path: menuItem.href }"
            @click.native="hideMenu"
          >
            <img :src="menuItem.icon" :alt="menuItem.title" />
            {{ menuItem.title }}
          </router-link>
        </template>
      </div>

      <button class="menu__logout" @click="logoutRequest">Выйти</button>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.aside {
  width: 404px;
  background-color: $light-primary;
  border-right: 1px solid $outline-light;
  height: 100dvh;
  overflow: hidden;

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-xl $space-m 0;
    margin-left: 24px;

    @media (max-width: 1024px) {
      padding: $space-l $space-l 0;
      margin-left: 0;
    }

    button {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      cursor: pointer;

      > img {
        filter: invert(1);
      }
    }
  }

  &__menu {
    padding: 24px 0;
    margin: 0;
    list-style: none;
    overflow: auto;
    height: calc(100dvh - 322px);

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $light-sixth;
      border-radius: 8px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }

  .menu {
    &__logout {
      border: none;
      background-color: transparent;
      cursor: pointer;
      @include body-1;
      color: $dark-fifth;
      padding: 0 24px 0 48px;
      margin: 24px 0 0;

      &:hover {
        color: $dark-third;
      }

      &:active {
        color: $dark-primary;
      }
    }

    .submenu {
      padding-left: 32px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s linear;

      &_opened {
        overflow: visible;
        max-height: 500px;
        animation: hide-scroll 0.25s backwards;

        @keyframes hide-scroll {
          from,
          to {
            overflow: hidden;
          }
        }
      }

      .menu__item {
        &:after {
          left: -80px;
          width: 100%;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding: 18px 24px 18px 48px;
      @include body-1;
      color: $dark-primary;
      position: relative;
      z-index: 1;
      cursor: pointer;
      text-decoration: none;

      > img {
        filter: invert(100%);
      }

      &_active {
        &:after {
          background: linear-gradient(
            90deg,
            rgba(33, 33, 33, 0.08) 0%,
            rgba(33, 33, 33, 0) 100%
          );
          opacity: 1 !important;
        }

        &:hover {
          &:after {
            background: linear-gradient(
              90deg,
              rgba(33, 33, 33, 0.04) 0%,
              rgba(33, 33, 33, 0) 100%
            ) !important;
          }
        }
      }

      .arrow {
        margin-left: auto;
        justify-self: flex-end;
        transition: transform 0.2s ease-in-out;
      }

      &_opened {
        svg {
          transform: rotate(-180deg);
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -48px;
        background-size: 100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover {
        &:after {
          background: linear-gradient(
            90deg,
            rgba(33, 33, 33, 0.04) 0%,
            rgba(33, 33, 33, 0) 100%
          );
          opacity: 1;
        }
      }

      &:active {
        color: $dark-primary;

        svg {
          path {
            fill: $dark-primary;
          }
        }

        &:after {
          opacity: 1;
          background: linear-gradient(
            90deg,
            rgba(33, 33, 33, 0.08) 0%,
            rgba(33, 33, 33, 0) 100%
          );
        }
      }
    }
  }

  @media (max-width: 1440px) {
    width: 330px;
  }

  @media (max-width: 1023px) {
    position: fixed;
    z-index: 999999;
    height: 100dvh;
    max-width: 425px;
    width: 100%;
    left: -100%;
    transition: left 0.4s ease;

    &__menu {
      height: calc(100dvh - 314px);
    }
  }

  &_opened {
    left: 0;
  }
}
</style>
