import { render, staticRenderFns } from "./MainButtonIcon.vue?vue&type=template&id=e6fa1406&scoped=true"
import script from "./MainButtonIcon.vue?vue&type=script&lang=js"
export * from "./MainButtonIcon.vue?vue&type=script&lang=js"
import style0 from "./MainButtonIcon.vue?vue&type=style&index=0&id=e6fa1406&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6fa1406",
  null
  
)

export default component.exports