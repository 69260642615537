export default function formatQualification(qualificationCode, short = false) {
  let qualificationName;

  switch (qualificationCode) {
    case "start":
      qualificationName = short ? "Старт" : "Старт";
      break;
    case "expert":
      qualificationName = short ? "Эксперт по продажам" : "Эксперт по продажам";
      break;
    case "star":
      qualificationName = short
        ? "Менеджер по продажам"
        : "Менеджер по продажам";
      break;
    case "double_star":
      qualificationName = short ? "Лидер продаж" : "Лидер продаж";
      break;
    case "diamond":
      qualificationName = short ? "Тимлидер продаж" : "Тимлидер продаж";
      break;
    case "double_diamond":
      qualificationName = short ? "Топ-лидер продаж" : "Топ-лидер продаж";
      break;
    case "grand_diamond":
      qualificationName = short
        ? "Директор по продажам"
        : "Директор по продажам";
      break;
    case "diamond_elite":
      qualificationName = short
        ? "Директор по продажам МУ"
        : "Директор по продажам международного уровня";
      break;
    case "ambassador":
      qualificationName = short ? "Амбассадор" : "Амбассадор";
      break;
    case "v_president":
      qualificationName = short
        ? "Вице-президент"
        : "Вице-президент дистрибьюторской сети";
      break;
    default:
      qualificationName = "Без квалификации";
      break;
  }

  return qualificationName;
}
