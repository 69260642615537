import API from "@/api";

const state = {
  tutorials: [],
  tutorial: null,
  hoursForBooking: [],

  lastPage: 1,
};

const getters = {};

const actions = {
  loadTutorials({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/tutorials")
        .then((response) => {
          commit("SET_TUTORIALS", response.data.data);
          commit("SET_LAST_PAGE", response.data.meta.last_page);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTutorial({ commit }, tutorialId) {
    return new Promise((resolve, reject) => {
      API.get(`api/tutorials/${tutorialId}/lessons`)
        .then((response) => {
          commit("SET_TUTORIAL", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkHoursForBooking({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get("api/exams/check", {
        params: {
          date: payload.date,
        },
      })
        .then((response) => {
          commit("SET_HOURS_FOR_BOOKING", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeExam(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/exams", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  destroyExam(_, examId) {
    return new Promise((resolve, reject) => {
      API.delete(`api/exams/${examId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkTest() {
    return new Promise((resolve, reject) => {
      API.get(`api/tutorials/tests/check`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  startTest() {
    return new Promise((resolve, reject) => {
      API.get(`api/tutorials/tests/start`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTest() {
    return new Promise((resolve, reject) => {
      API.get(`api/tutorials/tests/load`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  answerTest(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/tutorials/tests/answer`, {
        field_id: data.pollId,
        answers: data.data.variants,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeAnswerTest(_, data) {
    return new Promise((resolve, reject) => {
      API.put(`api/tutorials/tests/answer`, {
        field_id: data.pollId,
        answers: data.data.variants,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  finishTest() {
    return new Promise((resolve, reject) => {
      API.get(`api/tutorials/tests/finish`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TUTORIALS(state, payload) {
    state.tutorials = payload;
  },

  SET_TUTORIAL(state, payload) {
    state.tutorial = payload;
  },

  SET_LAST_PAGE(state, payload) {
    state.lastPage = payload;
  },

  SET_HOURS_FOR_BOOKING(state, payload) {
    state.hoursForBooking = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
