import API from "@/api";

const state = {
  companies: [],
  companiesRCPOS: [],
  bookedCompanies: [],
  rating: [],
  cities: [],
};

const getters = {};

const actions = {
  loadCompanies({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/companies`, {
        params: {
          for_current_month: data.isCurrentMonth ? 1 : 0,
        },
      })
        .then((response) => {
          commit("SET_COMPANIES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCompaniesRCPOS({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/companies/rcpos", {
        params: {
          for_current_month: data.isCurrentMonth ? 1 : 0,
        },
      })
        .then((response) => {
          commit("SET_COMPANIES_RCPOS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadBookedCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/company/booking")
        .then((response) => {
          commit("SET_BOOKED_COMPANIES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  searchCompanies(_, payload) {
    return new Promise((resolve, reject) => {
      API.get("api/company/booking/search", {
        params: {
          search: payload.search,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  bookCompany(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("api/company/booking", {
        inn: payload.inn,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkCompany(_, payload) {
    return new Promise((resolve, reject) => {
      API.get("api/company/booking/check", {
        params: {
          inn: payload.inn,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteBookedCompany(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(`api/company/booking/${payload.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTSPRating({ commit }, data) {
    const params = {
      currency: data.country.value,
      tariff: data.tariff.value,
      period: data.period.value,
      city: data.city.value,
      type: data.type,
      limit: 25,
    };
    return new Promise((resolve, reject) => {
      API.get("api/companies/rating", {
        params: params,
      })
        .then((response) => {
          commit("SET_RATING", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCities({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/trade-points/cities")
        .then((response) => {
          commit("SET_CITIES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_COMPANIES(state, payload) {
    state.companies = payload;
  },

  SET_COMPANIES_RCPOS(state, payload) {
    state.companiesRCPOS = payload;
  },

  SET_BOOKED_COMPANIES(state, payload) {
    state.bookedCompanies = payload;
  },

  SET_RATING(state, rating) {
    state.rating = rating;
  },

  SET_CITIES(state, payload) {
    state.cities = payload.map((city, index) => {
      return {
        id: index,
        value: city,
        name: city,
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
