import Vue from "vue";
import Vuex from "vuex";
import API from "@/api.js";

import auth from "./auth.store";
import balance from "./balance.store";
import news from "./news.store";
import qualification from "./qualification.store";
import referral from "./referral.store";
import representation from "./representation.store";
import tariff from "./tariff.store";
import transfer from "./transfer.store";
import tutorial from "./tutorial.store";
import account from "./account.store";
import structure from "./structure.store";
import bonuses from "./bonuses.store";
import subscription from "./subscription.store";
import poll from "./poll.store";
import licenses from "./licenses.store.js";
import tsp from "./tsp.store.js";
import financialCycles from "./financial-cycles.store.js";
import rating from "./rating.store";
import analytics from "./analytics.store.js";
import certificates from "./certificates.store";
import application from "./application.store";
import coorser from "./coorser.store";
import market from "./market.store";
import warnings from "@/store/warnings.store";

const debug = process.env.NODE_ENV !== "production";

// function loadStores() {
//   const context = require.context("@/store", true, /store.js$/i);
//   return context
//     .keys()
//     .map(context) // import module
//     .map((m) => m.default); // get `default` export from each resolved module
// }
//
// const resourceModules = {};
// loadStores().forEach((resource) => {
//   resourceModules[resource.name] = resource;
// });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notification: null,

    showMenu: false,

    innerWidth: window.innerWidth,
    yandexScriptStatus: "",
  },

  getters: {
    isMobile(state) {
      return state.innerWidth <= 1023;
    },
  },

  actions: {
    createNotification({ commit, state }, data) {
      if (state.notification) {
        commit("SET_NOTIFICATION", null);

        setTimeout(() => {
          commit("SET_NOTIFICATION", data);
        }, 50);
      } else {
        commit("SET_NOTIFICATION", data);
      }
    },

    loadDocuments(_, payload) {
      return new Promise((resolve, reject) => {
        API.get(`api/documents/${payload.page}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadRdoDocuments() {
      return new Promise((resolve, reject) => {
        API.get(`api/rdo`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  mutations: {
    SET_NOTIFICATION(state, data) {
      state.notification = data;
    },

    SET_SHOW_MENU(state, payload) {
      state.showMenu = payload;
    },

    SET_INNER_WIDTH(state, payload) {
      state.innerWidth = payload;
    },

    SET_YANDEX_SCRIPT_STATUS(state, payload) {
      state.yandexScriptStatus = payload;
    },
  },

  modules: {
    auth,
    balance,
    news,
    qualification,
    referral,
    representation,
    tariff,
    transfer,
    tutorial,
    account,
    structure,
    bonuses,
    subscription,
    poll,
    licenses,
    tsp,
    financialCycles,
    rating,
    analytics,
    certificates,
    application,
    coorser,
    market,
    warnings,
  },
  strict: debug,
});
