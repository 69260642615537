export const ALLOWED_USERS = [
  "79233362290",
  "79178556973",
  "79274623131",
  "79503109267",
  "79263387886",
  "79135513777",
  "79083296994",
  "79378367331",
  "79229995802",
  "79378367331",
  "79274039702",
  "79538563777",
  "79622934706",
  "79995173988",
  "79377733077",
  "79503111544",
  "79196325999",
  "79053197799",
  "79378539065",
  "79046747557",
  "79233362290",
  "79178556973",
  "79274623131",
  "79503109267",
  "79263387886",
  "79135513777",
  "79083296994",
  "79378367331",
  "79229995802",
  "79378367331",
  "79274039702",
  "79538563777",
  "79622934706",
  "79995173988",
  "79377733077",
  "79503111544",
  "79196325999",
  "79053197799",
  "79378539065",
  "79046747557",
];
