import store from "@/store";
import checkUserData from "@/package/helpers/check-user.helpers";

export default function checkUserBeforeEnter(to, from, next) {
  const user = store.state.auth.user;

  if (checkUserData(user)) {
    next("/");
  } else {
    next();
  }
}
