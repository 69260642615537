<script>
import formatQualification from "../../package/helpers/format-qualification.helpers";

export default {
  methods: { formatQualification },
  props: {
    qualification: {
      type: Object,
    },

    title: {
      type: Boolean,
      default: false,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showTitle() {
      return (
        (this.title || this.selected) &&
        this.qualification.code !== "without_qualification"
      );
    },

    imagePath() {
      if (this.qualification.code !== "without_qualification") {
        return require(`../../assets/qualifications/color/${this.qualification.code}.png`);
      } else {
        return require(`../../assets/qualifications/without_qualification.png`);
      }
    },
  },
};
</script>

<template>
  <router-link
    class="qualification-preview"
    :class="[
      {
        title: title && qualification.code !== 'without_qualification',
        selected: selected && !title,
        disabled: disabled,
      },
    ]"
    :to="{ name: 'Qualifications' }"
  >
    <img :src="imagePath" alt="" />

    <h2
      class="qualification-preview__name"
      :class="{
        'qualification-preview__name_small':
          qualification.code === 'without_qualification',
      }"
    >
      {{ formatQualification(qualification.code) }}
    </h2>
    <p v-if="showTitle" class="qualification-preview__title">
      Ваша квалификация
    </p>
  </router-link>
</template>

<style lang="scss" scoped>
.qualification-preview {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  height: 82px;

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  &__title {
    @include caption-1;
    color: $dark-second;
    z-index: 1;
  }

  &__name {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 4px;
    z-index: 1;

    &_small {
      @include body-1-bold;
      max-width: 168px;
    }
  }
}

.title {
  padding: 0 24px;

  .qualification-preview__name {
    @include body-1-bold;
  }
}

.selected {
  flex-flow: column-reverse;

  .qualification-preview__title {
    color: $dark-fifth;
    margin-bottom: 0;
  }
}
</style>
