export default function checkUserData(user) {
  const requiredFields = ["email", "promocode"];

  if (!user) return false;

  return requiredFields.every((item) => {
    if (item in user) return !!user[item];

    return false;
  });
}
