<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import Aside from "../aside/Aside";
import MobileHeader from "../MobileHeader";
import MainSelect from "@/components/helpers/MainSelect";
import MainNotice from "@/components/helpers/MainNotice.vue";

export default {
  name: "MainLayout",

  components: {
    MainNotice,
    Aside,
    MobileHeader,
    MainSelect,
  },

  data() {
    return {
      locales: [
        {
          id: "RUB",
          name: "RU",
        },
        {
          id: "KZT",
          name: "KZ",
        },
      ],

      locale: null,
    };
  },

  watch: {
    locale: {
      deep: true,
      handler() {
        if (this.selectedLocale.id !== this.locale.id) {
          this.setLocale(this.locale);
        }
      },
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedLocale: (state) => state.auth.selectedLocale,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      notification: (state) => state.notification,
      notifications: (state) => state.auth.notifications,
    }),

    ...mapGetters({
      getLocaleAccounts: "auth/getLocaleAccounts",
      getUserAccounts: "auth/getUserAccounts",
    }),

    hasAnotherLocales() {
      return !!this.getUserAccounts.find(
        (account) => account.currency !== "RUB"
      );
    },

    formattedNotice() {
      return {
        title: this.notification?.title || "",
        description: this.notification?.description || "",
        closed: this.notification?.closed || false,
        type: this.notification?.type || "info",
        group: this.notification?.group || "",
      };
    },
  },

  methods: {
    ...mapMutations({
      setLocale: "auth/SET_LOCALE",
      setNotification: "SET_NOTIFICATION",
    }),

    ...mapActions({
      createNotification: "createNotification",
      loadNotifications: "auth/loadNotifications",
      viewNotification: "auth/viewNotification",
    }),

    closeNotification(notification, link = null) {
      if (notification?.id) {
        this.viewNotification(notification.id).then(() => {
          this.loadNotificationsRequest();

          if (link) {
            window.location.href = link;
          }
        });
      }

      this.setNotification(null);
    },

    loadNotificationsRequest() {
      this.loadNotifications().then(() => {
        if (!this.notifications || this.notifications?.is_showed) {
          return;
        }

        this.createNotification({
          id: this.notifications?.id || null,
          title: this.notifications?.title || "",
          button_link: this.notifications?.button_link || "",
          button_label: this.notifications?.button_label || "",
          closed: true,
          type: this.notifications?.type || "error",
          description: this.notifications?.description || "",
          group: this.notifications?.group || "",
        });
      });
    },
  },

  mounted() {
    this.locale = this.selectedLocale;

    this.loadNotificationsRequest();
  },
};
</script>

<template>
  <div class="main-layout">
    <Aside />

    <div id="layoutBody" class="main-layout__body">
      <MobileHeader />

      <div v-if="selectedBinaryAccount" class="main-layout__title title">
        <div class="title__links">
          <router-link
            exact-active-class="title__links_active"
            :to="{ name: 'ReferralLinks' }"
          >
            <img src="../../assets/icons/referral_links.svg" alt="" />
            Реферальные ссылки
          </router-link>
        </div>
      </div>

      <div v-if="$route.meta && $route.meta.title" class="main-layout__name">
        <h1>
          <span
            v-if="$route.meta.icon"
            class="main-layout__icon"
            :class="$route.meta.icon"
          />
          <span>{{ $route.meta.title }}</span>
        </h1>

        <MainSelect
          v-if="hasAnotherLocales && $route.meta.title === 'Баланс' && false"
          :options="locales"
          :selected-option="locale"
        />
      </div>

      <router-view />
    </div>

    <MainNotice
      v-if="notification"
      :title="formattedNotice.title"
      :description="formattedNotice.description"
      :notice-closed="formattedNotice.closed"
      :notice-type="formattedNotice.type"
      :notice-group="formattedNotice.group"
      @close="closeNotification(notification)"
    >
      <template v-if="notification.button_link && notification.button_label">
        <a
          class="main-layout__notification-link"
          :href="notification.button_link"
          @click.prevent="
            closeNotification(notification, notification.button_link)
          "
        >
          {{ notification.button_label }}
        </a>
      </template>
    </MainNotice>
  </div>
</template>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  height: 100%;

  &__notification-link {
    margin-top: 16px;
    text-decoration: none;
    color: $blue;
    @include body-1;
    display: block;
  }

  &__name {
    padding: 48px $space-xxl 64px;
    display: flex;
    align-items: center;

    h1 {
      @include title-2;
      margin-right: $space-s;
      display: flex;
      align-items: center;
      gap: $space-s;
    }

    @media (max-width: 900px) {
      padding: 40px $space-xxl 32px;
    }

    @media (max-width: 576px) {
      padding: 40px $space-m 32px;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;

    @media (max-width: 1024px) {
      width: 32px;
      height: 32px;
    }
  }

  &__body {
    flex: 1;
    overflow: auto;

    @media (max-width: 1023px) {
      padding-top: 60px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    background-color: $background-grey;
    padding: 24px;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .title {
    &__notification {
      padding: 2px 24px 2px 0;
      border-right: 1px solid $light-fifth;

      button {
        width: 24px;
        height: 24px;
        background: url("../../assets/icons/notifications.svg");
        background-size: contain;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    &__links {
      position: relative;
      @include body-1;
      line-height: 1;

      &_active {
        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $dark-primary;
          position: absolute;
          bottom: -4px;
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $dark-primary;

        img {
          margin-right: $space-s;
        }
      }
    }
  }
}
</style>
