import { ALLOWED_USERS } from "@/package/const/analytics-allowed-users";
import { ALLOWED_USERS_TSP } from "@/package/const/tsp-rating-allowed-users";

export const MENU_ITEMS = [
  {
    icon: require("../../assets/icons/sidebar/dashboard.svg"),
    title: "Главная",
    href: "/",
  },
  {
    icon: require("../../assets/icons/sidebar/bonuses.svg"),
    title: "Бонусы",
    href: "/bonuses",
  },
  {
    icon: require("../../assets/icons/sidebar/balance.svg"),
    title: "Финансы",
    showNestedItems: false,
    nestedItems: [
      {
        title: "Баланс",
        href: "/balance",
      },
      // // todo закомментировать
      // {
      //   title: "Сертификаты",
      //   href: "/certificates",
      //   allowedTariffCodes: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
      // },
      {
        title: "Абонентская плата",
        href: "/subscription",
        allowedTariffCodes: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
      },
      {
        title: "Переводы",
        href: "/transfers",
      },
      {
        title: "Аналитика",
        href: "/analytics",
        allowedUsers: ALLOWED_USERS,
      },
      {
        title: "Калькулятор личной выгоды",
        href: "/calculator-personal-gain",
      },
    ],
  },
  {
    icon: require("../../assets/icons/sidebar/tariffs.svg"),
    title: "Тарифы",
    href: "/tariffs",
  },
  {
    icon: require("../../assets/icons/sidebar/structure.svg"),
    title: "Структура",
    href: "/structure",
  },
  {
    icon: require("../../assets/icons/sidebar/tsp.svg"),
    title: "ТСП",
    showNestedItems: false,
    allowedTariffCodes: ["vip_d", "vip_gold_d"],
    nestedItems: [
      {
        title: "Подключенные ТСП",
        href: "/connected-tsp",
      },
      {
        title: "Работа с ТСП",
        href: "/documents/tsp",
      },
      {
        title: "План продаж",
        href: "/sales-plan",
      },
      {
        title: "Калькулятор дохода от ТСП",
        href: "/calculator-tsp-income",
      },
    ],
  },
  {
    icon: require("../../assets/icons/sidebar/support.svg"),
    title: "Помощь",
    href: "https://support.rc.today/sign-in?source=cd",
  },
  {
    icon: require("../../assets/icons/sidebar/more.svg"),
    title: "Еще",
    showNestedItems: false,
    nestedItems: [
      {
        title: "Рейтинг дистрибьюторов",
        href: "/rating-distributors",
      },
      {
        title: "Рейтинг ТСП",
        href: "/rating-tsp",
        allowedTariffCodes: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
      },
      {
        title: "Квалификации",
        href: "/qualifications",
      },
      {
        title: "Обучение",
        href: "/study",
      },
      {
        title: "Новости",
        href: "/news",
      },
      {
        title: "RC HOUSE",
        href: "/rc-house",
      },
      {
        title: "Общественная деятельность",
        href: "/public-activities",
      },
      {
        title: "Документы",
        href: "/documents",
      },
    ],
  },
];
