import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "@/i18n";
import Maska from "maska";
import * as Sentry from "@sentry/vue";
import VueSanitize from "vue-sanitize";

import "@/scss/app.scss";

import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad);

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component("datetime", Datetime);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
Vue.use(FloatingVue);

Vue.use(Maska);

import VCalendar from "v-calendar";

Vue.use(VCalendar);

import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

import Vue2TouchEvents from "vue2-touch-events";

Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

const defaultOptions = {
  allowedTags: ["a", "br"],
  allowedAttributes: {
    a: ["href"],
  },
};
Vue.use(VueSanitize, defaultOptions);

export const eventBus = new Vue();

Sentry.init({
  Vue,
  dsn: "https://fa543aeb2a8b4890af82ec08f2513938@sentry.iilab.ru/34",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: process.env.NODE_ENV !== "development",
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
