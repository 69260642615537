import API from "@/api";

const state = {
  referralLinks: [],

  selectedPromocode: null,
};

const getters = {
  getReferralLinkByPromocode: (state) => (promocode) => {
    return state.referralLinks.find(
      (referralLink) => referralLink.promocode === promocode
    );
  },
};

const actions = {
  loadReferralLinks({ commit }, binaryAccount) {
    return new Promise((resolve, reject) => {
      API.get(`api/referals/${binaryAccount}`)
        .then((response) => {
          commit("SET_REFERRAL_LINKS", response.data.data);

          if (response.data.data.length) {
            commit("SET_PROMOCODE", response.data.data[0].promocode);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_REFERRAL_LINKS(state, payload) {
    state.referralLinks = payload;
  },

  SET_PROMOCODE(state, payload) {
    state.selectedPromocode = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
