import API from "@/api";

const state = {
  transfersStatus: "",
  transfers: [],
  transfersLastPage: 1,

  transferAccountFrom: null,
  transferAccountTo: null,

  recipientInfo: null,
};

const getters = {};

const actions = {
  loadTransfers({ commit }, data) {
    commit("SET_TRANSFERS_STATUS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get("api/transfers/history", {
        params: {
          page: data.page,
        },
      })
        .then((response) => {
          if (data.page === 1) {
            commit("SET_TRANSFERS", response.data.data);
          } else {
            commit("ADD_TRANSFERS", response.data.data);
          }
          commit("SET_TRANSFERS_LAST_PAGE", response.data.meta.last_page);
          commit("SET_TRANSFERS_STATUS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_TRANSFERS_STATUS", "ERROR");

          reject(error);
        });
    });
  },

  loadRecipientInfo({ commit }, accountId) {
    return new Promise((resolve, reject) => {
      API.get("api/transfers/recipient", {
        params: {
          account_to_id: accountId,
        },
      })
        .then((response) => {
          commit("SET_RECIPIENT_INFO", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeTransfer(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/transfers", {
        account_from_id: data.accountFromId,
        account_to_id: data.accountToId,
        amount: data.amount,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TRANSFERS(state, payload) {
    state.transfers = payload;
  },

  ADD_TRANSFERS(state, payload) {
    state.transfers = state.transfers.concat(payload);
  },

  SET_TRANSFERS_STATUS(state, payload) {
    state.transfersStatus = payload;
  },

  SET_TRANSFERS_LAST_PAGE(state, payload) {
    state.transfersLastPage = payload;
  },

  SET_RECIPIENT_INFO(state, payload) {
    state.recipientInfo = payload;
  },

  SET_TRANSFER_ACCOUNT_FROM(state, payload) {
    state.transferAccountFrom = payload;
  },

  SET_TRANSFER_ACCOUNT_TO(state, payload) {
    state.transferAccountTo = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
