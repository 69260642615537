<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      SET_INNER_WIDTH: "SET_INNER_WIDTH",
    }),

    onResize() {
      this.SET_INNER_WIDTH(window.innerWidth);
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  height: 100%;
}
</style>
