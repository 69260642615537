import API from "@/api";

const state = {
  status: "",
  distributorNewsStatus: "",
  publicActivitiesNewsStatus: "",

  news: null,
  distributorNews: [],
  publicActivitiesNews: [],

  distributorNewsLastPage: 1,
  publicActivitiesNewsLastPage: 1,
};

const getters = {
  getDistributorNewsByCount: (state) => (count) => {
    return state.distributorNews.slice(0, count);
  },

  getPublicActivitiesNewsByCount: (state) => (count) => {
    return state.publicActivitiesNews.slice(0, count);
  },
};

const actions = {
  loadDistributorNews({ commit }, payload) {
    commit("SET_DISTRIBUTOR_NEWS_STATUS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get(`api/distributor_news/news`, {
        params: payload,
      })
        .then((response) => {
          if (payload.page === 1) {
            commit("SET_DISTRIBUTOR_NEWS", response.data.data);
            commit(
              "SET_DISTRIBUTOR_NEWS_LAST_PAGE",
              response.data.meta.last_page
            );
          } else {
            commit("ADD_DISTRIBUTOR_NEWS", response.data.data);
          }

          commit("SET_DISTRIBUTOR_NEWS_STATUS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_DISTRIBUTOR_NEWS_STATUS", "ERROR");

          reject(error);
        });
    });
  },

  loadPublicActivitiesNews({ commit }, payload) {
    commit("SET_PUBLIC_ACTIVITIES_NEWS_STATUS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get(`api/social_activity/news`, {
        params: payload,
      })
        .then((response) => {
          if (payload.page === 1) {
            commit("SET_PUBLIC_ACTIVITIES_NEWS", response.data.data);
            commit(
              "SET_PUBLIC_ACTIVITIES_NEWS_LAST_PAGE",
              response.data.meta.last_page
            );
          } else {
            commit("ADD_PUBLIC_ACTIVITIES_NEWS", response.data.data);
          }

          commit("SET_PUBLIC_ACTIVITIES_NEWS_STATUS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_PUBLIC_ACTIVITIES_NEWS_STATUS", "ERROR");

          reject(error);
        });
    });
  },

  loadNews({ commit }, id) {
    commit("SET_STATUS", "LOADING");

    return new Promise((resolve, reject) => {
      API.get(`api/news/${id}`)
        .then((response) => {
          commit("SET_NEWS", response.data.data);
          commit("SET_STATUS", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          commit("SET_STATUS", "ERROR");

          reject(error);
        });
    });
  },
};

const mutations = {
  SET_DISTRIBUTOR_NEWS(state, payload) {
    state.distributorNews = payload;
  },

  ADD_DISTRIBUTOR_NEWS(state, payload) {
    state.distributorNews = state.distributorNews.concat(payload);
  },

  SET_DISTRIBUTOR_NEWS_STATUS(state, payload) {
    state.distributorNewsStatus = payload;
  },

  SET_DISTRIBUTOR_NEWS_LAST_PAGE(state, payload) {
    state.distributorNewsLastPage = payload;
  },

  SET_PUBLIC_ACTIVITIES_NEWS(state, payload) {
    state.publicActivitiesNews = payload;
  },

  ADD_PUBLIC_ACTIVITIES_NEWS(state, payload) {
    state.publicActivitiesNews = state.publicActivitiesNews.concat(payload);
  },

  SET_PUBLIC_ACTIVITIES_NEWS_STATUS(state, payload) {
    state.publicActivitiesNewsStatus = payload;
  },

  SET_PUBLIC_ACTIVITIES_NEWS_LAST_PAGE(state, payload) {
    state.publicActivitiesNewsLastPage = payload;
  },

  SET_NEWS(state, payload) {
    state.news = payload;
  },

  SET_STATUS(state, payload) {
    state.status = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
