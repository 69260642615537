import API from "@/api";

const state = {
  qualifications: [],
  qualification: null,
  invitedTSPCount: 0,
  historyQualifications: null,
};

const getters = {};

const actions = {
  loadQualifications({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/qualifications")
        .then((response) => {
          commit("SET_QUALIFICATIONS", response.data.reverse());

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadQualification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.post(`api/binary-accounts/${payload.binaryAccount}/qualification`)
        .then((response) => {
          commit("SET_QUALIFICATION", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadInvitedTSPCount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get("api/qualifications/invited-tsp")
        .then((response) => {
          commit("SET_INVITED_TSP_COUNT", response.data.invited_tsp_count);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadHistoryQualifications({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/qualifications/history")
        .then((response) => {
          commit("SET_HISTORY_QUALIFICATIONS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_QUALIFICATIONS(state, payload) {
    state.qualifications = payload;
  },

  SET_QUALIFICATION(state, payload) {
    state.qualification = payload;
  },

  SET_INVITED_TSP_COUNT(state, payload) {
    state.invitedTSPCount = payload;
  },

  SET_HISTORY_QUALIFICATIONS(state, payload) {
    state.historyQualifications = payload;
  },
};

export default {
  namespaced: true,
  name: "qualification",
  state,
  getters,
  actions,
  mutations,
};
