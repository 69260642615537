var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-notice",class:[
    { 'main-notice_auto-close': !_vm.noticeClosed },
    {
      'main-notice_certificate':
        _vm.noticeGroup === 'activity_application_approved' ||
        _vm.noticeGroup === 'activity_application_declined' ||
        _vm.isCompanyBookingExpires,
    },
  ]},[(_vm.noticeClosed)?_c('MainButtonIcon',{staticClass:"main-notice__button-close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"icon-close"})]):_vm._e(),(_vm.showNoticeIcon)?_c('div',{staticClass:"main-notice__icon"},[(_vm.noticeType === 'success')?_c('span',{staticClass:"icon-success"}):_vm._e(),(_vm.noticeType === 'error')?_c('span',{staticClass:"icon-warning"}):_vm._e(),(_vm.noticeType === 'info')?_c('span',{staticClass:"icon-info-thin"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"main-notice__content content"},[_c('h5',{staticClass:"content__title",style:({ 'margin-bottom': _vm.description ? '4px' : '0' })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"content__description",domProps:{"innerHTML":_vm._s(_vm.description)}}),_vm._t("default")],2),(
      _vm.noticeGroup === 'activity_application_approved' ||
      _vm.noticeGroup === 'activity_application_declined'
    )?_c('img',{staticClass:"main-notice__img",attrs:{"src":require("../../assets/images/certificate_notice.svg"),"alt":""}}):_vm._e(),(_vm.isCompanyBookingExpires)?_c('img',{staticClass:"main-notice__img",attrs:{"src":require("../../assets/tsp-timer.svg"),"alt":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }