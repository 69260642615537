<script>
export default {
  name: "MainObserver",

  emits: ["intersect", "notIntersect"],
  props: {
    id: {
      type: String,
      default: "observer",
    },

    options: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      observer: null,
    };
  },

  mounted() {
    const options = this.options ?? {};

    this.observer = new IntersectionObserver(([entry]) => {
      if (!entry) {
        return;
      }

      if (entry.isIntersecting) {
        this.$emit("intersect");
        return;
      }

      if (entry.boundingClientRect.top > 0) {
        this.$emit("notIntersect", "bottom");
      } else if (entry.boundingClientRect.bottom < 0) {
        this.$emit("notIntersect", "top");
      }
    }, options);

    this.observer.observe(this.$refs[this.id]);
  },

  beforeDestroy() {
    this.observer.disconnect();
  },
};
</script>

<template>
  <div :ref="id" class="observer" />
</template>
