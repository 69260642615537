import API from "@/api";

const state = {
  representations: [],
  cities: [],
};

const getters = {};

const actions = {
  loadRepresentations({ commit }, city) {
    return new Promise((resolve, reject) => {
      API.get("api/representations", {
        params: {
          city: city,
        },
      })
        .then((response) => {
          const representations = response.data.map((representation) => {
            representation.name = representation.address;
            delete representation.address;

            return representation;
          });

          commit("SET_REPRESENTATIONS", representations);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCities({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/representations/cities")
        .then((response) => {
          let cities = [];

          response.data.forEach((city, index) => {
            cities.push({ id: index, name: city });
          });
          commit("SET_CITIES", cities);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeRepresentation(_, representationId) {
    return new Promise((resolve, reject) => {
      API.post(`api/representations/${representationId}/associate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_REPRESENTATIONS(state, payload) {
    state.representations = payload;
  },

  SET_CITIES(state, payload) {
    state.cities = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
