import API from "@/api";
import store from "./index";

const state = {
  certificates: [],
  certificatesNominals: [],
  certificatesStatuses: null,
};

const getters = {};

const actions = {
  loadCertificates({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/certificates", {
        params: {
          status: data.status,
          page: data.page,
        },
      })
        .then((response) => {
          if (data.page === 1) {
            commit("SET_CERTIFICATES", response.data.data);
          } else {
            commit("SET_CERTIFICATES", [
              ...state.certificates,
              ...response.data.data,
            ]);
          }

          commit(
            "SET_CERTIFICATES_STATUSES",
            response.data?.meta?.statuses ?? null
          );

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCertificatesNominals({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/certificates/amounts")
        .then((response) => {
          commit(
            "SET_CERTIFICATES_NOMINALS",
            response?.data?.data.sort((a, b) => a - b) ?? []
          );

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeCertificate(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/certificates", {
        amount: data.amount,
        code: data.code,
      })
        .then((response) => {
          store.dispatch("createNotification", {
            type: "success",
            title: "Сертификат куплен!",
          });

          resolve(response);
        })
        .catch((error) => {
          store.dispatch("createNotification", {
            type: "error",
            title: "Не получилось купить сертификат",
            description: "Что-то пошло не так, повторите операцию позже",
          });

          reject(error);
        });
    });
  },

  certificateConfirm(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/confirmation/start", {
        action: "certificates.store",
        type: data.type,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  certificateShare(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/certificates/${data.certificateId}/share`, {
        email: data.email,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_CERTIFICATES(state, payload) {
    state.certificates = payload;
  },

  ADD_CERTIFICATE(state, payload) {
    state.certificates.push(payload);
  },

  SET_CERTIFICATES_NOMINALS(state, payload) {
    state.certificatesNominals = payload;
  },

  SET_CERTIFICATES_STATUSES(state, payload) {
    state.certificatesStatuses = Object.assign({}, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
