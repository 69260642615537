import API from "@/api";

const state = {
  financialCycles: null,

  financialCyclesWeeks: [],
};

const getters = {
  getFinancialCyclesWeeksAmounts(state) {
    return state.financialCyclesWeeks.map((week) => {
      return week.amount;
    });
  },
};

const actions = {
  loadFinancialCycles({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/binary-accounts/${payload.binaryAccountId}/cycles`, {
        params: {
          from: payload.dateRange?.start ? payload.dateRange.start : "",
          to: payload.dateRange?.end ? payload.dateRange.end : "",
          page: payload?.page ? payload.page : 1,
          per_page: 10,
        },
      })
        .then((response) => {
          commit("SET_FINANCIAL_CYCLES", response.data);

          if (payload.page === 1) {
            commit("SET_FINANCIAL_CYCLES_WEEKS", response.data.weeks.all);
          } else {
            commit("ADD_FINANCIAL_CYCLES_WEEKS", response.data.weeks.all);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_FINANCIAL_CYCLES(state, payload) {
    state.financialCycles = payload;
  },

  SET_FINANCIAL_CYCLES_WEEKS(state, payload) {
    state.financialCyclesWeeks = payload;
  },

  ADD_FINANCIAL_CYCLES_WEEKS(state, payload) {
    state.financialCyclesWeeks = state.financialCyclesWeeks.concat(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
