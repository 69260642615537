import API from "@/api";

const state = {
  rating: null,
};

const getters = {
  getDistributors: (state) => (count) => {
    return state.rating?.rating.slice(0, count) ?? [];
  },

  getPlaceNumber: (state) => (person_id, ratingType) => {
    if (ratingType === "earnings") {
      return null;
    }

    return (
      state.rating?.rating.find(
        (distributor) => distributor.person_id === person_id
      )?.place_number ?? null
    );
  },
};

const actions = {
  loadRating({ commit }, data) {
    const params = {
      type: data.type.value,
      country: data.country.value,
      qualification_code: data.qualification_code.code,
    };

    return new Promise((resolve, reject) => {
      API.get("api/rating/income", {
        params: params,
      })
        .then((response) => {
          commit("SET_RATING", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTSPRating({ commit }, data) {
    const params = {
      type: data.type.value,
      country: data.country.value,
      company_tariff_code: data.company_tariff_code.value,
    };

    return new Promise((resolve, reject) => {
      API.get("api/rating/trade-points", {
        params: params,
      })
        .then((response) => {
          commit("SET_TSP_RATING", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_RATING(state, payload) {
    state.rating = payload;
  },

  SET_TSP_RATING(state, payload) {
    state.rating = payload;
  },

  SET_PLACE_NUMBER(state, placeNumber) {
    state.placeNumber = placeNumber;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
