<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: { MainButton },

  props: {
    value: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: "",
    },

    showOptions: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    backgroundColor: {
      type: String,
      default: "#f8f8f8",
    },

    isShowButtonSearch: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    showSearchButton() {
      return this.value.length > 0 && this.isShowButtonSearch;
    },

    showButtonClose() {
      return this.value.length > 0 && !this.loading;
    },

    showAllResultsButton() {
      return this.options.length > 8;
    },
  },
};
</script>

<template>
  <div class="main-search">
    <div class="main-search__wrapper">
      <div class="main-search__input input">
        <span class="input__icon-search"></span>

        <input
          type="text"
          :value="value"
          :placeholder="placeholder"
          :style="{ 'background-color': backgroundColor }"
          @input="$emit('input', $event.target.value)"
          @keyup.enter="$emit('search')"
        />

        <button
          v-if="showButtonClose"
          class="button-icon"
          @click="$emit('clear')"
        >
          <span class="input__icon-close"></span>
        </button>
        <span v-if="loading" class="input__icon-loading"></span>
      </div>

      <MainButton
        v-if="showSearchButton"
        class="main-search__button-search"
        :color="'gold'"
        :title="'Найти'"
        :disabled="loading"
        @click="$emit('search')"
      />
    </div>

    <div v-if="showOptions" class="main-search__results">
      <template v-if="options.length > 0"><slot></slot></template>

      <template v-else><p>Ничего не найдено</p></template>

      <button v-show="showAllResultsButton" @click="$emit('showAllResults')">
        Показать все результаты
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-search {
  position: relative;

  &__wrapper {
    display: flex;

    .main-button {
      max-width: 88px;
    }
  }

  &__button-search {
    margin-left: 8px;
  }

  .input {
    position: relative;
    width: 100%;
    height: 48px;

    input {
      width: 100%;
      height: 100%;
      border: 1px solid $light-fifth;
      border-radius: $space-s;
      background-color: $light-second;
      outline: none;
      padding: 0 44px;
      color: $dark-primary;

      &::placeholder {
        color: $dark-sixth;
      }
    }

    &__icon-search,
    &__icon-close,
    &__icon-loading {
      min-width: 20px;
      height: 20px;
      display: inline-block;
      background: $dark-sixth;
      position: absolute;
    }

    &__icon-search {
      mask: url("../../assets/icons/search.svg") 0 0 / cover no-repeat;
      left: $space-m;
      top: 14px;
    }

    &__icon-close {
      mask: url("../../assets/icons/close.svg") 0 0 / cover no-repeat;
      right: $space-m;
      top: 14px;
    }

    &__icon-loading {
      mask: url("../../assets/icons/loading.svg") 0 0 / cover no-repeat;
      background: $dark-primary;
      right: $space-m;
      top: 14px;
    }
  }

  &__results {
    position: absolute;
    z-index: 99;
    width: 100%;
    background-color: $light-primary;
    box-shadow: 0px 0px 30px rgba(169, 169, 169, 0.15);
    border-radius: $space-s;
    margin-top: $space-s;
    padding: $space-l;

    p {
      @include body-1;
      color: $dark-primary;
    }

    button {
      border: none;
      background-color: transparent;
      color: $blue;
      @include body-1;
      cursor: pointer;
      margin-top: $space-l;
    }
  }
}
</style>
