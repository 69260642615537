<script>
export default {
  name: "MainButton",

  props: {
    color: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "button",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    padding: {
      type: String,
      default: "0",
    },

    iconPosition: {
      type: String,
      default: "right",
    },
  },

  computed: {
    formattedStyle() {
      if (this.padding !== "0") {
        return {
          padding: this.padding,
          width: "auto",
          height: "auto",
        };
      } else {
        return {};
      }
    },
  },
};
</script>

<template>
  <button
    class="main-button"
    :class="[color, { 'main-button_loading': loading }]"
    :style="formattedStyle"
    :type="type"
    :disabled="disabled"
    @click.stop="$emit('click')"
  >
    <slot v-if="!loading" name="iconLeft" />
    {{ title }}

    <slot v-if="!loading" name="iconRight"></slot>
    <span class="main-button__icon-loading" />
  </button>
</template>

<style scoped lang="scss">
.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 8px;
  @include text-2;
  cursor: pointer;
  padding: 13px 0;
  position: relative;
  z-index: 1;
  height: 48px;

  &__icon {
    display: none;
  }

  &_loading {
    pointer-events: none;

    .main-button__icon-loading {
      mask: url("../../assets/icons/loading.svg") 0 0 / cover no-repeat;
      display: inline-block;
      min-width: 20px;
      width: 20px;
      height: 20px;
      margin-left: $space-s;
      background-color: $dark-primary;
    }

    &:where(.dark) {
      .main-button__icon-loading {
        background-color: $light-primary;
      }
    }
  }

  &:disabled {
    background-color: $light-fifth !important;
    border: 1px solid #bdbdbd;
    color: $dark-fifth;
    cursor: not-allowed;
    background-image: none;
    box-shadow: none !important;
  }

  &:disabled:after,
  &:disabled:before {
    content: none;
  }

  &:disabled:hover {
    filter: none;
  }

  &:disabled:active {
    background-image: none;
  }
}

.error {
  background-color: transparent !important;
  border: 1px solid $red !important;
  background-image: none !important;
  color: $red !important;

  &:after,
  &:before {
    content: none !important;
  }

  &:hover {
    filter: none !important;
  }
}

.dark {
  background-color: $dark-primary;
  color: $light-primary;
  transition: all 0.15s linear;

  .main-button__icon {
    background: $light-primary;
  }

  &:hover {
    background-color: $gold-main;
    color: $dark-primary;
  }

  &:active {
    background-color: $dark-primary;
    color: $light-primary;
  }
}

.blue {
  padding: 0;
  color: $blue;
  height: fit-content;
  width: fit-content;
  background-color: transparent;
}

.transparent {
  background-color: transparent;
  color: $dark-primary;
}

.grey {
  background-color: $background-grey;
  border: 1px solid $background-grey;
  color: $dark-primary;
}

.outlined {
  background-color: transparent;
  border: 1px solid $dark-primary;
  color: $dark-primary;
}

.outlined-grey {
  background-color: transparent;
  border: 1px solid $dark-fourth;
  color: $dark-fourth;

  &:hover {
    background-color: $gold-main;
    box-shadow: 0 0 20px 0 $gold-main;
  }

  &:active {
    box-shadow: none;
  }
}

.outlined-white {
  background: none;
  border: 1px solid $background-white;
  color: $background-white;
  padding: 14px 24px;
}

.gold {
  background-color: $gold-main;
  color: $dark-primary;
  transition: all 0.15s linear;

  &:hover {
    box-shadow: 0 0 20px $gold-main;
  }

  &:active {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
    background-color: $gold-main !important;
    border: $dark-primary;
    color: inherit;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
