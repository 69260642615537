var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"qualification-preview",class:[
    {
      title: _vm.title && _vm.qualification.code !== 'without_qualification',
      selected: _vm.selected && !_vm.title,
      disabled: _vm.disabled,
    },
  ],attrs:{"to":{ name: 'Qualifications' }}},[_c('img',{attrs:{"src":_vm.imagePath,"alt":""}}),_c('h2',{staticClass:"qualification-preview__name",class:{
      'qualification-preview__name_small':
        _vm.qualification.code === 'without_qualification',
    }},[_vm._v(" "+_vm._s(_vm.formatQualification(_vm.qualification.code))+" ")]),(_vm.showTitle)?_c('p',{staticClass:"qualification-preview__title"},[_vm._v(" Ваша квалификация ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }