import API from "@/api";
import store from "@/store/index";

const state = {
  courses: [],
  course: null,
};

const getters = {};

const actions = {
  loadCourses({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/integrations/kursir")
        .then((response) => {
          commit("SET_COURSES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Попробуйте позже",
            type: "error",
          });
          reject(error);
        });
    });
  },

  attachToCourse(_, payload) {
    return new Promise((resolve, reject) => {
      API.post("api/integrations/kursir/attach", payload)
        .then((response) => {
          store.dispatch("createNotification", {
            title: "Готово!",
            description: "Вы зарегистрированы на курс",
            type: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          if (
            error.response.status === 400 &&
            error.response.data.message === "user.missing_values"
          ) {
            store.dispatch("createNotification", {
              title: "Недостаточно данных профиля",
              description: `<a target="_blank" href='${process.env.VUE_APP_PASSPORT_URL}profile'>Перейти к заполнению</a>`,
              type: "error",
            });
            reject(error);
            return;
          }

          store.dispatch("createNotification", {
            title: "Ошибка при регистрации",
            description: "Попробуйте позже",
            type: "error",
          });
          reject(error);
        });
    });
  },

  loadCourse({ commit }, course_id) {
    return new Promise((resolve, reject) => {
      API.get(`api/integrations/kursir/${course_id}`)
        .then((response) => {
          commit("SET_COURSE", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Попробуйте позже",
            type: "error",
          });
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_COURSES(state, courses) {
    state.courses = courses;
  },

  SET_COURSE(state, course) {
    state.course = course;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
