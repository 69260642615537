import API from "@/api";
import store from "@/store";

const state = {
  application: null,
  suggestedBirthplaces: [],
  suggestedIssuedPlaces: [],
  suggestedAddresses: [],
};

const getters = {};

const actions = {
  checkLatestApplication({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/activity-application/check")
        .then((response) => {
          if (
            response.data?.id === undefined ||
            Array.isArray(response.data?.data)
          ) {
            commit("SET_APPLICATION", null);
          } else {
            commit("SET_APPLICATION", response.data);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadBirthplaces({ commit }, birthplace) {
    return new Promise((resolve, reject) => {
      API.get("api/activity-application/suggest/birthplace", {
        params: {
          birthplace: birthplace,
        },
      })
        .then((response) => {
          commit("SET_SUGGESTED_BIRTHPLACES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadIssuedPlaces({ commit }, issuedBy) {
    return new Promise((resolve, reject) => {
      API.get("api/activity-application/suggest/issued-by", {
        params: {
          issued_by: issuedBy,
        },
      })
        .then((response) => {
          commit("SET_SUGGESTED_ISSUED_PLACES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadAddresses({ commit }, address) {
    return new Promise((resolve, reject) => {
      API.get("api/activity-application/suggest/address", {
        params: {
          address: address,
        },
      })
        .then((response) => {
          commit("SET_SUGGESTED_ADDRESSES", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeApplication(_, data) {
    return new Promise((resolve, reject) => {
      API.postForm("api/activity-application", data)
        .then((response) => {
          store.dispatch("createNotification", {
            type: "success",
            title: "Заявка отправлена!",
            closed: false,
          });

          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            store.dispatch("createNotification", {
              type: "error",
              title: "При заказе справки возникла ошибка!",
              description:
                "Попробуйте снова или обратитесь в техническую поддержку hello@rc.company",
              closed: false,
            });
          }
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_APPLICATION(state, payload) {
    state.application = payload;
  },

  SET_SUGGESTED_BIRTHPLACES(state, pyaload) {
    state.suggestedBirthplaces = pyaload;
  },

  SET_SUGGESTED_ISSUED_PLACES(state, payload) {
    state.suggestedIssuedPlaces = payload;
  },

  SET_SUGGESTED_ADDRESSES(state, payload) {
    state.suggestedAddresses = payload;
  },
};

export default {
  namespaced: true,
  name: "application",
  state,
  getters,
  mutations,
  actions,
};
