import API from "@/api";

const state = {
  polls: [],
};

const getters = {};

const actions = {
  loadPolls({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/polls")
        .then((response) => {
          commit("SET_POLLS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  answerThePoll(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(`api/polls/${payload.pollId}/answer`, payload.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_POLLS(state, payload) {
    state.polls = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
