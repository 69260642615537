<script>
export default {
  emits: ["click"],
};
</script>

<template>
  <button class="main-button-icon" type="button" @click.stop="$emit('click')">
    <slot></slot>
  </button>
</template>

<style lang="scss" scoped>
.main-button-icon {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
}
</style>
