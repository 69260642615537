import API from "@/api";

const state = {
  bonusFounder: null,

  bonusQuickstart: null,

  bonusLeader: null,
};

const getters = {
  getBonusQuickstartCompleted: (state) => {
    return state.bonusQuickstart.blocks.filter((item) => item.is_complete);
  },

  getBonusQuickstartInProgress: (state) => {
    return state.bonusQuickstart.blocks.filter((item) => !item.is_complete);
  },
};

const actions = {
  loadBonusFounder({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/bonuses/founder-bonus")
        .then((response) => {
          commit("SET_BONUS_FOUNDER", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadBonusQuickstart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/bonuses/${payload.binaryAccount}/startbonus`)
        .then((response) => {
          commit("SET_BONUS_QUICKSTART", response.data);

          resolve(response);
        })
        .catch((error) => {
          commit("SET_BONUS_QUICKSTART", null);

          reject(error);
        });
    });
  },

  loadBonusLeader({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/bonuses/${payload.binaryAccount}/leaderbonus`)
        .then((response) => {
          commit("SET_BONUS_LEADER", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_BONUS_FOUNDER(state, payload) {
    state.bonusFounder = payload;
  },

  SET_BONUS_QUICKSTART(state, payload) {
    state.bonusQuickstart = payload;
  },

  SET_BONUS_LEADER(state, payload) {
    state.bonusLeader = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
