import { DateTime } from "luxon";

export default function GroupDateArray(array, defaultArray = []) {
  const cloneDefaultArray = structuredClone(defaultArray);
  const userTimeZone = DateTime.local().zoneName;

  return array.reduce((acc, item) => {
    const timezoneDate = DateTime.fromISO(item.created_at, {
      zone: "utc",
    }).setZone(userTimeZone);

    const formattedDate = timezoneDate.toFormat("dd.MM.yyyy");
    const isoDate = timezoneDate.toISO();

    const existingGroup = acc.find((group) => group.date === formattedDate);

    if (existingGroup) {
      existingGroup.items.push({ ...item, created_at: isoDate });
    } else {
      acc.push({
        date: formattedDate,
        items: [{ ...item, created_at: isoDate }],
      });
    }

    return acc;
  }, cloneDefaultArray);
}
