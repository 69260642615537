import API from "@/api.js";

const state = {
  documents: null,
};

const getters = {};

const actions = {
  loadDocuments({ commit }) {
    const PAGE = "licenses";

    return new Promise((resolve, reject) => {
      API.get(`api/documents/${PAGE}`)
        .then((response) => {
          commit("SET_DOCUMENTS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_DOCUMENTS(state, payload) {
    state.documents = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
